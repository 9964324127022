import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import styles from "./Questions.module.scss";
import { Question as QuestionType } from "../../../models/championship";
import Text from "../../UI/atoms/Text";
import { alphabet } from "../../../constants/values";
import { colors } from "../../../sdk/colors";
import { ReactComponent as CheckMark } from "../../../assets/icons/checkMark.svg";
import { useNavigate } from "react-router-dom";
import Button, { NextButton, PrevButton } from "../../UI/atoms/Button";
import { useSubmitAnswersMutation } from "../../../redux/features/championship/championshipApiSlice";
import ChampionshipResult from "../ChampionshipResult";
import { Skeleton } from "@chakra-ui/react";
import { useGetUserQuery } from "../../../redux/features/user/userApiSlice";
import { championshipHelper } from "../../../utils/championshipHelper";
import LoaderContext from "../../../context/loader.context";
import { RootState } from "../../../redux/store";
import { useAppSelector } from "../../../redux/hooks";
import { parseAndReplaceFraction } from "../../../utils/numericHelper";
import { useToast } from "../../../context/toast.context";
import { ErrorResponse } from "../../../models/utilityTypes";

function Question({
  index,
  activeQuestionIdx,
  question,
  onChange,
  onSubmit,
}: {
  index: number;
  activeQuestionIdx: number;
  question: QuestionType;
  onChange: (result: number) => void;
  onSubmit: (result: { id: number; answer: number }) => void;
}) {
  const [selectedOption, setSelectedOption] = useState<number | undefined>();

  const handleSelectOption = (index: number) => {
    setSelectedOption(index);
  };

  const isSeletectedOption = (index: number): boolean => {
    return index === selectedOption;
  };

  const isAnswer = (index: number | undefined): boolean => {
    return question.answer === index;
  };
  const hasSelected = typeof selectedOption === "number";

  const isCurrentQuestion = () => index === activeQuestionIdx;

  useEffect(() => {
    if (hasSelected) {
      onChange(isAnswer(selectedOption) ? 1 : 0);
      onSubmit({ id: question.id, answer: selectedOption });
    }
  }, [selectedOption]);
  const token = useAppSelector(
    (state: RootState) => state.auth.activeProfileToken
  );
  // const { data: user } = useGetUserQuery(`${token}`);
  // const { data: championshipsData } = useGetChampionshipsQuery(undefined);
  // const type = championshipHelper.getUserType(
  //   Number(user?.grade),
  //   championshipsData?.data
  // );

  return (
    <div
      style={{
        top: (index + 1) * 0.3,
        left: (index + 1) * 0.1,
        transform: useMemo(
          () => hasSelected && isCurrentQuestion,
          [activeQuestionIdx]
        )
          ? "translate(200%) rotate(0deg)"
          : "translate(0%) rotate(-360deg)",
        transition: "0.8s",
      }}
      className={styles.Question}
    >
      <Text variant="heading 5" style={{ marginBottom: "20px" }}>
        Question {activeQuestionIdx + 1}
      </Text>
      <Text variant="heading 4" fontWeight="light">
        {parseAndReplaceFraction(question.question)}
      </Text>
      <div className={styles.OptionsWrapper}>
        {question.options.map((option, index) => {
          let background: React.CSSProperties["background"];
          if (hasSelected && index === question.answer) {
            background =
              "linear-gradient(267deg, #DBF5EA 13.14%, #BCFFB9 114.66%)";
          } else if (!isAnswer(index) && isSeletectedOption(index)) {
            background = "#FFC8C8";
          } else {
            background = "#fff";
          }
          return (
            <button disabled={hasSelected} key={index} style={{ background }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ color: colors.gray, marginRight: "35px" }}
                  fontWeight="light"
                >
                  {alphabet[index]}
                </Text>
                <Text>{parseAndReplaceFraction(option)}</Text>
              </div>
              <div onClick={() => handleSelectOption(index)} id={styles.circle}>
                {isSeletectedOption(index) && <CheckMark />}
              </div>
            </button>
          );
        })}
      </div>
    </div>
  );
}

function Questions({
  questions,
  onQuestionChange,
  variant = "championship",
  onIndexChange,
  lessonId,
  topicId,
  count,
}: {
  questions: QuestionType[] | [];
  onQuestionChange?: (progress: string) => void;
  variant?: "championship" | "lesson";
  onIndexChange?: (index: number) => void;
  lessonId?: number;
  topicId?: number;
  count?: number;
}) {
  const { show } = useToast();
  const [submitAnswers, { isLoading }] = useSubmitAnswersMutation();

  const { setLoader: setLoading } = useContext(LoaderContext);

  const navigate = useNavigate();
  const [activeQuestionIdx, setActiveQuestionIdx] = useState<number>(0);
  const [isFinshed, setIsFinished] = useState<boolean>(false);
  const [results, setResults] = useState<number[] | []>([]);
  const [submitResults, setSubmitResults] = useState<
    { id: number; answer: number }[] | []
  >([]);

  console.log(activeQuestionIdx, "index");

  useEffect(() => {
    onIndexChange && onIndexChange(activeQuestionIdx);
  }, [activeQuestionIdx]);

  const updateResult = useCallback((value: number) => {
    setResults((prevResults) => [...prevResults, value]);
  }, []);

  const updateSubmitResult = useCallback(
    (value: { id: number; answer: number }) => {
      setSubmitResults((prevResults) => [...prevResults, value]);
    },
    []
  );
  console.log(submitResults, "submit results");

  const handleSubmitAnswers = async (goNext: boolean) => {
    if (activeQuestionIdx === 0 && submitResults.length === 0) {
      navigate(-1);
      return;
    }
    else if (activeQuestionIdx > 0 && submitResults.length === 0 && !goNext) {
      setIsFinished(true);
      updateResult(0)
      return;
    }
    const submitValues: { questionId: number; answer: number } = {
      questionId: submitResults[submitResults.length - 1]?.id,
      answer: submitResults[submitResults.length - 1]?.answer,
    };
    console.log(submitValues, questions);

    try {
      setLoading(!goNext);
      const response = await submitAnswers([submitValues]).unwrap();
      setLoading(false);
      setSubmitResults([]);
      console.log(response, "submit response");

      if (goNext) {
        setActiveQuestionIdx((prevValue) => prevValue + 1);
      } else {
        setIsFinished(true);
      }
    } catch (e) {
      setLoading(false);
      show({
        message: (e as ErrorResponse)?.data?.message || "Something went wrong",
        variant: "info",
      });
      console.log(e);
    }
  };

  const handleLessonsSubmit = async (goNext: boolean) => {
    if (goNext) {
      setActiveQuestionIdx((prevValue) => prevValue + 1);
    } else {
      setIsFinished(true);
    }
  };

  return (
    <div className={styles.Questions}>
      {isFinshed ? (
        <ChampionshipResult
          variant={variant}
          lessonId={lessonId}
          results={results}
          topicId={topicId}
        />
      ) : (
        <>
          <div style={{ position: "relative", top: "80px" }}>
            {questions.map((question, index) => (
              <Question
                activeQuestionIdx={activeQuestionIdx}
                key={question.id}
                index={index}
                question={question}
                onChange={(value) => updateResult(value)}
                onSubmit={(value) => updateSubmitResult(value)}
              />
            ))}
          </div>
          <div className={styles.ButtonsWrapper}>
            <PrevButton onClick={() => handleSubmitAnswers(false)}>
              End
            </PrevButton>
            <NextButton
              disabled={activeQuestionIdx !== results.length - 1}
              isLoading={isLoading}
              onClick={
                variant === "championship"
                  ? () =>
                      handleSubmitAnswers(
                        activeQuestionIdx !== Number(count) - 1
                      )
                  : () =>
                      handleLessonsSubmit(
                        activeQuestionIdx !== questions.length - 1
                      )
              }
            >
              Next
            </NextButton>
          </div>
        </>
      )}
    </div>
  );
}

export default Questions;
